import { FC, c } from 'lib/component-utils'
import { forwardRef } from 'react'

type Props = {
  title?: string
  small?: boolean
}

const Badge: FC<Props, 'span'> = ({ className, title, small = false, children, ...rest }, ref) => (
  <span
    title={title}
    ref={ref}
    className={c`inline-flex items-center py-0.5 rounded-full font-medium ${small} text-xs px-2.5 | text-sm px-3 ${className}`}
    {...rest}
  >
    {children}
  </span>
)

export default forwardRef<HTMLSpanElement, Props>(Badge as any) as FC<Props>
