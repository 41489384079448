'use client'
import { Listbox, Popover } from '@headlessui/react'
import {
  ChevronUpIcon,
  GlobeAltIcon,
  GlobeIcon,
  MenuIcon,
  TranslateIcon,
  XIcon,
} from '@heroicons/react/outline'
import { CheckCircleIcon, ChevronDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import Badge from 'components/base/Badge'
import Button from 'components/base/Button'
import Link from 'components/base/Link'
import Tooltip from 'components/base/Tooltip'
import { links } from 'configs/links'
import { AnimatePresence, motion } from 'framer-motion'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useState } from 'react'
import { usePromoCodeContext } from './lib/promoCodeContext'
import { useTrackGoal } from 'lib/metrics'
import PromoCode from 'components/landing/PromoCode'
import { useRouter } from 'next/router'
import { setCookie } from 'nookies'
import Select from 'components/base/Select'
import { match } from 'ts-pattern'

const MobileNavLink: FC<{ href: string; name: string }, typeof Link> = ({
  children,
  name,
  href,
  ...props
}) => {
  const trackGoal = useTrackGoal()
  const onClick = name === 'telegram_bot' ? () => trackGoal('moved-to-telegram-bot') : () => {}
  const isInternal = href.startsWith('/') && !href.startsWith('/help')
  const LinkComponent = isInternal ? Link : 'a'

  const linkProps = isInternal ? {} : { target: '_blank', rel: 'noreferrer' }

  return (
    // @ts-ignore
    <Popover.Button
      as={LinkComponent as any}
      {...linkProps}
      href={href}
      onClick={onClick}
      className="block text-base tracking-tight leading-7 text-gray-700"
      {...props}
    >
      {children}
    </Popover.Button>
  )
}

type Props = {
  showSignupButton?: boolean
}

const Header: FC<Props> = ({ showSignupButton = false }) => {
  const ctx = useUserContext()
  const promoCode = usePromoCodeContext()
  const { t, format, lang } = useIntl()
  const trackGoal = useTrackGoal()
  const router = useRouter()
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const setLanguage = (code: string) => {
    setCookie(null, 'lang', code, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    })
    router.reload()
  }

  return (
    <header>
      <nav>
        <div className="flex relative z-50 justify-between px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div
            className={c`relative z-10 flex items-center gap-8 sm:gap-16 ${
              promoCode !== null
            } lg:gap-8 | lg:gap-16`}
          >
            <Link href="/" aria-label="Home" className="shrink-0">
              <img src={ctx.theme.logoDark} alt="Logo" className="w-auto h-6" />
              {ctx.theme.hostType === 'sellscreen' && lang === 'zh' && (
                <span className="block mt-1 -mb-4 text-lg font-medium">赛讴选品</span>
              )}
            </Link>
            <div
              className={c`hidden lg:gap-8 [@media(min-width:1150px)]:gap-10 ${
                promoCode !== null
              } xl:flex | [@media(min-width:1150px)]:flex`}
            >
              {links.map(({ name, href, hosts, links }, index) => {
                if (!hosts.includes(ctx.theme.hostType)) return null
                if (name === 'blog' && lang === 'en') return null

                if (links) {
                  return (
                    <Tooltip
                      key={name}
                      sizing="custom"
                      className="overflow-hidden rounded-lg"
                      side="bottom"
                      align="start"
                      content={
                        <div>
                          {links.map(({ name, href }) => {
                            const onClick =
                              name === 'telegram_bot'
                                ? () => trackGoal('moved-to-telegram-bot')
                                : () => {}
                            const isInternal = href.startsWith('/') && !href.startsWith('/help')
                            const LinkComponent = isInternal ? Link : 'a'

                            const linkProps = isInternal
                              ? {}
                              : { target: '_blank', rel: 'noreferrer' }

                            return (
                              <LinkComponent
                                {...linkProps}
                                key={name}
                                href={href ?? '#'}
                                onClick={onClick}
                                className="block w-full hover:bg-gray-100 py-2.5 px-4"
                              >
                                <span className="relative z-10 whitespace-nowrap">
                                  {t(`links.${name}`)}
                                </span>
                              </LinkComponent>
                            )
                          })}
                        </div>
                      }
                    >
                      <div
                        key={name}
                        className="cursor-pointer relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <AnimatePresence>
                          {hoveredIndex === index && (
                            <motion.span
                              className="absolute inset-0 bg-gray-100 rounded-lg"
                              layoutId="hoverBackground"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1, transition: { duration: 0.15 } }}
                              exit={{
                                opacity: 0,
                                transition: { duration: 0.15, delay: 0.2 },
                              }}
                            />
                          )}
                        </AnimatePresence>
                        <span className="inline-flex relative z-10 items-center space-x-1 whitespace-nowrap">
                          <span>{t(`links.${name}`)}</span>
                          <ChevronDownIcon className="w-4 h-4" />
                        </span>
                      </div>
                    </Tooltip>
                  )
                }

                const onClick =
                  name === 'telegram_bot' ? () => trackGoal('moved-to-telegram-bot') : () => {}
                const isInternal = href.startsWith('/') && !href.startsWith('/help')
                const LinkComponent = isInternal ? Link : 'a'

                const linkProps = isInternal ? {} : { target: '_blank', rel: 'noreferrer' }

                return (
                  <LinkComponent
                    {...linkProps}
                    key={name}
                    href={href ?? '#'}
                    onClick={onClick}
                    className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <AnimatePresence>
                      {hoveredIndex === index && (
                        <motion.span
                          className="absolute inset-0 bg-gray-100 rounded-lg"
                          layoutId="hoverBackground"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1, transition: { duration: 0.15 } }}
                          exit={{
                            opacity: 0,
                            transition: { duration: 0.15, delay: 0.2 },
                          }}
                        />
                      )}
                    </AnimatePresence>
                    <span className="relative z-10 whitespace-nowrap">{t(`links.${name}`)}</span>
                  </LinkComponent>
                )
              })}
            </div>
            {promoCode && <PromoCode promoCode={promoCode} />}
          </div>
          <div className="flex gap-6 items-center">
            {ctx.theme.hostType === 'sellematics' && (
              <Select<'en' | 'ru'>
                selected={lang as 'en' | 'ru'}
                setSelected={setLanguage}
                dropdownClassName="right-0"
                options={[lang as 'en' | 'ru', lang === 'en' ? 'ru' : 'en']}
                display={(value: 'en' | 'ru') =>
                  match(value)
                    .with('en', () => 'Middle East')
                    .with('ru', () => 'Россия')
                    .exhaustive()
                }
                button={() => (
                  <Listbox.Button className="flex items-center px-3 py-2 space-x-2 rounded-md hover:bg-gray-100">
                    <GlobeAltIcon className="w-4 h-4 text-gray-500" />
                    <span className="font-medium text-gray-700">{t`region`}</span>
                    <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                  </Listbox.Button>
                )}
              />
            )}
            {ctx.theme.hostType === 'sellscreen' && (
              <Select<'en' | 'zh'>
                selected={lang as 'en' | 'zh'}
                setSelected={setLanguage}
                dropdownClassName="right-0"
                options={[lang as 'en' | 'zh', lang === 'en' ? 'zh' : 'en']}
                display={(value: 'en' | 'zh') =>
                  match(value)
                    .with('en', () => 'English')
                    .with('zh', () => '中文')
                    .exhaustive()
                }
                button={() => (
                  <Listbox.Button className="flex items-center px-3 py-2 space-x-2 rounded-md hover:bg-gray-100">
                    <TranslateIcon className="w-4 h-4 text-gray-500" />
                    <span className="font-medium text-gray-700">
                      {match(lang as 'en' | 'zh')
                        .with('en', () => 'English')
                        .with('zh', () => '中文')
                        .exhaustive()}
                    </span>
                    <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                  </Listbox.Button>
                )}
              />
            )}
            {ctx.theme.hostType === 'uzum' && (
              <Select<'ru' | 'uz'>
                selected={lang as 'ru' | 'uz'}
                setSelected={setLanguage}
                dropdownClassName="right-0"
                options={[lang as 'ru' | 'uz', lang === 'ru' ? 'uz' : 'ru']}
                display={(value: 'ru' | 'uz') =>
                  match(value)
                    .with('uz', () => "O'zbek")
                    .with('ru', () => 'Русский')
                    .exhaustive()
                }
                button={() => (
                  <Listbox.Button className="flex items-center px-3 py-2 space-x-2 rounded-md hover:bg-gray-100">
                    <TranslateIcon className="w-4 h-4 text-gray-500" />
                    <span className="font-medium text-gray-700">
                      {match(lang as 'ru' | 'uz')
                        .with('uz', () => "O'zbek")
                        .with('ru', () => 'Русский')
                        .exhaustive()}
                    </span>
                    <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                  </Listbox.Button>
                )}
              />
            )}
            <Popover
              className={c`${promoCode !== null} xl:hidden | [@media(min-width:1150px)]:hidden`}
            >
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 translate-y-[3px] -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) =>
                      open ? (
                        <ChevronUpIcon className="w-6 h-6" />
                      ) : (
                        <MenuIcon className="w-6 h-6" />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 backdrop-blur bg-gray-300/60"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 px-6 pt-32 pb-6 bg-gray-50 rounded-b-2xl shadow-2xl origin-top shadow-gray-900/20"
                        >
                          <div className="space-y-4">
                            {links
                              .flatMap((link) => {
                                if (!link.hosts.includes(ctx.theme.hostType)) return []
                                if (link.name === 'blog' && lang === 'en') return []
                                return link.href ? [link] : link.links!
                              })
                              .map(({ name, href }) => {
                                return (
                                  <MobileNavLink key={name} name={name} href={href ?? '#'}>
                                    {t(`links.${name}`)}
                                  </MobileNavLink>
                                )
                              })}
                          </div>
                          <div className="flex flex-col gap-4 mt-8">
                            <Button as={Link} theme="white" href="/login/">
                              {t`landing.log_in`}
                            </Button>
                            {!['sellematics', 'uzum'].includes(ctx.theme.hostType) && (
                              <Button
                                as={Link}
                                theme="accent"
                                href="/signup/"
                                onClick={() => trackGoal('landing-clicked-register')}
                              >
                                {t`landing.try_for_free`}
                              </Button>
                            )}
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
            <Button as={Link} theme="white" href="/login" className="hidden lg:block">
              {t`landing.log_in`}
            </Button>
            {!showSignupButton && !['sellematics'].includes(ctx.theme.hostType) && (
              <Button
                as={Link}
                theme="primary"
                href="/signup/"
                className="hidden lg:block"
                onClick={() => trackGoal('landing-clicked-register-header')}
              >
                {t`sign_up`}
              </Button>
            )}
            {showSignupButton && (
              <Button as={Link} theme="accent" href="/signup/" className="hidden lg:block">
                {t`landing.try_for_free`}
              </Button>
            )}
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
