import { CheckCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import Badge from 'components/base/Badge'
import Tooltip from 'components/base/Tooltip'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import type { PromoCode } from 'lib/dto'
import { useIntl } from 'lib/intl-utils'
import { useState } from 'react'

type Props = {
  promoCode: PromoCode
  alwaysShortText?: boolean
}

const PromoCode: FC<Props> = ({ promoCode, alwaysShortText = false }) => {
  const ctx = useUserContext()
  const { t, format } = useIntl()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Tooltip
      rootProps={{ open }}
      sizing="custom"
      className="px-3 py-3 rounded-lg"
      disabled={!promoCode}
      content={
        <div className="">
          <p className="mb-2">
            {t`landing.promo_code`}{' '}
            <span className="font-medium tracking-wider">{promoCode?.code}</span>
          </p>
          {promoCode?.freeDays && (
            <p className="flex items-center space-x-1.5 mt-1.5">
              <CheckCircleIcon className="w-5 h-5 text-green-500" />
              <span>
                {promoCode.freeDays} {t('day', { count: promoCode.freeDays })}{' '}
                {t`landing.days_free`}
              </span>
            </p>
          )}
          {promoCode?.tariffs && promoCode.tariffs.length > 0 && (
            <p className="flex items-center space-x-1.5 mt-1.5">
              <CheckCircleIcon className="w-5 h-5 text-green-500" />
              <span>
                {t`landing.subscription_starting_from`}{' '}
                {format.price(
                  Math.min(
                    promoCode.tariffs[0].pricePerMonth,
                    promoCode.tariffs[0].pricePer3Months,
                    promoCode.tariffs[0].pricePer6Months,
                    promoCode.tariffs[0].pricePerYear
                  ),
                  {
                    currency: ctx.theme.hostType === 'sellmonitor' ? 'RUB' : 'USD',
                  }
                )}{' '}
                {t`a_month`}
              </span>
            </p>
          )}
        </div>
      }
    >
      <div
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onFocus={handleOpen}
        onBlur={handleClose}
        className={c`px-0.5 w-max cursor-default rounded-full bg-gradient-to-tr from-lime-300 to-lime-400 py-0.5 transition-opacity duration-500 ease-in ${Boolean(
          promoCode
        )} opacity-100 | opacity-0`}
      >
        <Badge className="space-x-2 bg-white border-2 border-transparent">
          <p>
            <span>{t`landing.promo_code`}</span>
            <span className={c`hidden ${!alwaysShortText} sm:inline lg:hidden xl:hidden`}>
              {' '}
              {t`landing.promo_code_active`}
            </span>
          </p>
          <QuestionMarkCircleIcon className="w-[18px] text-gray-600 shrink-0" />
        </Badge>
      </div>
    </Tooltip>
  )
}

export default PromoCode
