import { Listbox, Transition } from '@headlessui/react'
import { DisplayExtractor, KeyExtractor } from 'lib/type-utils'
import { FC } from 'react'
import { c } from 'lib/component-utils'
import DropdownMenu from './DropdownMenu'
import SelectButton from './SelectButton'
import { AnchorPropsWithSelection } from '@headlessui/react/dist/internal/floating'

type Props<T> = {
  selected: T
  setSelected: (value: T) => void
  options: T[]
  optionKey?: KeyExtractor<T>
  display?: DisplayExtractor<T>
  className?: string
  dropdownClassName?: string
  dropdownAnchor?: AnchorPropsWithSelection
  small?: boolean
  placeholder?: string
  label?: string
  disabled?: boolean
  button?: FC<{
    open: boolean
    selected: T
    display?: DisplayExtractor<T>
    placeholder?: string
    [key: string]: any
  }>
}

const Select = <T,>({
  selected,
  setSelected,
  options,
  optionKey,
  display,
  className,
  dropdownClassName,
  dropdownAnchor,
  small,
  placeholder,
  label,
  disabled,
  button: Button = SelectButton,
}: Props<T>) => {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <div className={c`relative ${className}`}>
          {label && (
            <Listbox.Label className="inline-block w-full mb-1 text-sm font-medium text-black">
              {label}
            </Listbox.Label>
          )}
          <Button
            open={open}
            small={small}
            selected={selected}
            display={display}
            placeholder={placeholder}
            disabled={disabled}
            className="max-w-full min-w-full"
          />
          <Transition
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DropdownMenu
              options={options}
              display={display}
              optionKey={optionKey}
              className={c`${dropdownClassName}`}
              anchor={dropdownAnchor}
            />
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default Select
