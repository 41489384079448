import { ListboxButton } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { c } from 'lib/component-utils'
import { DisplayExtractor, extract } from 'lib/type-utils'

type Props<T> = {
  open: boolean
  small?: boolean
  selected: T
  display?: DisplayExtractor<T>
  placeholder?: string
  disabled?: boolean
  className?: string
}

const SelectButton = <T,>({
  open,
  small,
  selected,
  display,
  placeholder,
  disabled,
  className,
}: Props<T>) => {
  return (
    <ListboxButton
      className={c`relative pl-3 pr-10 text-left text-black bg-white border border-gray-300 hover:border-gray-400 rounded-md focus:outline-none focus-visible:ring-1 focus-visible:ring-primary-500 focus-visible:border-primary-500 sm:text-sm
        ${Boolean(small)} py-1 | py-2
        ${open} ring-1 ring-primary-500 border-primary-500 hover:border-primary-500
        ${Boolean(
          disabled
        )} !bg-gray-50 !text-gray-400 !border-gray-300 hover:!border-gray-300 focus-within:ring-0 cursor-not-allowed ${className}`}
    >
      <span className="block truncate">
        {extract(selected, display) ?? <span className="text-gray-500">{placeholder}</span>}
      </span>
      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <ChevronDownIcon className="w-4 h-4 text-gray-400" aria-hidden="true" />
      </span>
    </ListboxButton>
  )
}

export default SelectButton
